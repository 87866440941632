import React from 'react'
import { StyledLink } from './style'

export const Button: React.FC<any> = (props: any) => {
  return (
    <StyledLink
      to={props.linkTo}
      className={`${props.variant ? props.variant : ''} ${
        props.size ? props.size : ''
      } ${props.sp ? props.sp : ''}`}
    >
      {props.linkTitle}
    </StyledLink>
  )
}
