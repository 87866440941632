import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { Flex, Box } from '@rebass/grid/emotion'

import {
  WrapperFlex,
  StyledHeaderOuter,
  StyledMenu,
  NavBox,
  MenuButton,
  AdditionalMobileItems,
  StyledNameText,
  StyledIcon,
  StyledNameLink,
  DropDownMoreLinks,
  MoreItem,
} from './style'

import { linkResolver } from '@prismic/linkResolverHelper'
import useScrollingUp from '@hooks/scrollUp'
import { Button } from '@components/button'
import { useOutsideClick } from '@hooks/onClickOutside'
import { LangSwitcher } from '@components/lang-switcher'

type HeaderProps = {
  props: {
    activeMeta: any
    menu: any
  }
}

export const Header: React.FC<HeaderProps> = ({ props }) => {
  const { menu, activeMeta } = props

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [showDropDown, setShowDropDown] = useState<boolean>(false)
  const isScrollingUp = useScrollingUp()

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown)
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const handleClickOutside = () => {
    setShowDropDown(false)
  }

  const ref = useOutsideClick(handleClickOutside)

  const logo = props?.menu?.data?.logo[0]

  const renderedMenuLinks = menu?.data?.menu_links
    ? menu?.data?.menu_links.map((menuLink: any, index: number) => {
        const hasTarget = menuLink.link.target
        if (hasTarget) {
          return (
            <li key={`nav-${index}`}>
              <Link to={menuLink.link.url} target={hasTarget}>
                {menuLink.label.text}
              </Link>
            </li>
          )
        }
        const linkResolved = linkResolver(menuLink.link)
        return (
          <li key={`nav-${index}`}>
            <Link to={linkResolved}>{menuLink.label.text}</Link>
          </li>
        )
      })
    : null

  const renderedMoreMenuLinks = menu?.data?.more_menu_links
    ? menu?.data?.more_menu_links.map((menuLink: any, index: number) => {
        const hasTarget = menuLink.link.target
        if (hasTarget) {
          return (
            <li key={`nav-${index}`}>
              <Link to={menuLink.link.url} target={hasTarget}>
                {menuLink.label}
              </Link>
            </li>
          )
        }
        const linkResolved = linkResolver(menuLink.link)
        return (
          <li key={`nav-${index}`}>
            <Link to={linkResolved}>{menuLink.label}</Link>
          </li>
        )
      })
    : null
  return (
    <StyledHeaderOuter
      className={`${isScrollingUp ? 'sticky-header' : 'hide-header'}`}
    >
      <WrapperFlex justifyContent="space-between" alignItems="center">
        <>
          <MenuButton as="button" onClick={toggleMenu}>
            {activeMeta.lang === 'en-gb' ? 'Menu' : 'Menü'}
          </MenuButton>
          <NavBox as="nav" className={showMenu ? 'is-menu-open' : ''}>
            <div className="background"></div>
            <StyledMenu>
              {renderedMenuLinks}
              <MoreItem className={showDropDown ? 'is-dropdown-open' : ''}>
                <a onClick={toggleDropDown} ref={ref}>
                  {menu?.data?.more_label}
                  <svg
                    className="chevron"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#2c3e50"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M6 9l6 6l6 -6" />
                  </svg>
                </a>
                <DropDownMoreLinks>{renderedMoreMenuLinks}</DropDownMoreLinks>
              </MoreItem>
              {!!activeMeta.alternate_languages.length && (
                <li>
                  <LangSwitcher props={activeMeta} />
                </li>
              )}
            </StyledMenu>
            <AdditionalMobileItems>
              <Flex justifyContent="space-between">
                <StyledNameText className="root-link"></StyledNameText>
                <StyledIcon onClick={toggleMenu}>&times;</StyledIcon>
              </Flex>
            </AdditionalMobileItems>
          </NavBox>
        </>
        <Box>
          <StyledNameLink className="root-link" to={logo?.link.url}>
            {logo?.name.text}
          </StyledNameLink>
        </Box>
        <span className="only-desktop">
          <Button
            linkTo={menu?.data?.cta_button[0].call_to_action_button.url}
            linkTitle={menu?.data?.cta_button[0].label}
          ></Button>
        </span>
        <span className="only-mobile">
          <Button
            linkTo={menu?.data?.cta_button[0].call_to_action_button.url}
            linkTitle={'Boat Tour'}
          ></Button>
        </span>
      </WrapperFlex>
    </StyledHeaderOuter>
  )
}

Header.displayName = 'Header'

export const query = graphql`
  fragment header on PrismicMenu {
    data {
      logo {
        link {
          url
        }
        name {
          text
        }
      }
      menu_links {
        label {
          text
        }
        link {
          uid
          type
          target
          lang
          url
        }
      }
      more_label
      more_menu_links {
        label
        link {
          uid
          type
          target
          lang
          url
        }
      }
      cta_button {
        label
        call_to_action_button {
          url
          type
          uid
          target
          lang
        }
      }
    }
  }
`
