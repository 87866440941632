import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const StyledLink = styled(Link)`
  font-size: 1rem;
  background: #000;
  color: #fff;
  border: 1px solid #000;
  &[aria-current] {
    color: #fff;
  }

  padding: 0.5rem 1.2rem;

  &.outline {
    background: #fff;
    color: #000;
    border: 1px solid;
  }

  &.large {
    padding: 0.75rem 1.5rem;
  }

  &.mr-1 {
    margin-right: 1rem;
  }
`
