import { rem } from '@styles/02-tools/tools.common'

export const oWrapperOuter = () => `padding: 0 5vw; max-width: 100vw;`

export const oSmallWrapperInner = () =>
  `max-width: ${rem(920)}rem;margin: 0 auto;`

export const oWrapperInner = () => `
  max-width: ${rem(1280)}rem; margin: 0 auto;
`

export const oLargeWrapperInner = () =>
  `max-width: ${rem(1440)}rem;
  margin: 0 auto;`
