import styled from '@emotion/styled'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'

export const StyledMain = styled.main`
  section {
    margin-bottom: 5rem;
  }

  section.has_undeline {
    margin-bottom: 4rem;
  }

  section.is-first-section {
    margin-top: 104px;
    ${breakpoint.gt('md')`margin-top: 154px;`}
  }
`
