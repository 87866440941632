import { graphql } from 'gatsby'
import React from 'react'
import { Box, Flex } from '@rebass/grid'
import Link from 'gatsby-link'

import {
  StyledFooterOuter,
  StyledFooterInner,
  StyledFooterSectionTitle,
  StyledFooterFormTitle,
  StyledFooterForm,
  StyledFooterSectionLinks,
  StyledFlex,
} from './style'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Footer: React.FC<any> = (props: any) => {
  const {
    custom_code_title,
    custom_code,
    background_image,
    copyright,
    support_section_title,
    support_section,
    legal_section_title,
    legal_section,
    social_section_title,
    social_section,
  } = props.props.data

  //  const {}
  return (
    <StyledFooterOuter>
      <GatsbyImage
        className="gatsby-image-element"
        image={background_image?.gatsbyImageData}
        alt={''}
      />
      <StyledFooterInner>
        <StyledFlex flexWrap="wrap">
          <StyledFooterForm width={[1, 1, 1, 2 / 5]}>
            <StyledFooterFormTitle>{custom_code_title}</StyledFooterFormTitle>
            <div dangerouslySetInnerHTML={{ __html: custom_code.text }}></div>
          </StyledFooterForm>
          <Box width={[1, 1, 1, 1 / 5]} mb="1.5rem">
            <StyledFooterSectionTitle>
              {support_section_title}
            </StyledFooterSectionTitle>
            {support_section.map((node, index) => {
              return (
                <ul key={`footer-section-${index}`}>
                  <StyledFooterSectionLinks>
                    <Link to={node.link_url.url}>
                      <span>{node.link_title}</span>
                    </Link>
                  </StyledFooterSectionLinks>
                </ul>
              )
            })}
          </Box>
          <Box width={[1, 1, 1, 1 / 5]} mb="1.5rem">
            <StyledFooterSectionTitle>
              {legal_section_title}
            </StyledFooterSectionTitle>
            {legal_section.map((node, index) => {
              return (
                <ul key={`footer-section-${index}`}>
                  <StyledFooterSectionLinks>
                    <Link to={node.link_url.url}>
                      <span>{node.link_title}</span>
                    </Link>
                  </StyledFooterSectionLinks>
                </ul>
              )
            })}
          </Box>
          <Box width={[1, 1, 1, 1 / 5]}>
            <StyledFooterSectionTitle>
              {social_section_title}
            </StyledFooterSectionTitle>
            {social_section.map((node, index) => {
              return (
                <ul key={`footer-section-${index}`}>
                  <StyledFooterSectionLinks>
                    <Link to={node.link_url.url}>
                      <span>{node.link_title}</span>
                    </Link>
                  </StyledFooterSectionLinks>
                </ul>
              )
            })}
          </Box>
        </StyledFlex>
      </StyledFooterInner>
      <StyledFooterInner className="copyright" outerAttributes={{ as: 'div' }}>
        {copyright} {new Date().getFullYear()}
      </StyledFooterInner>
    </StyledFooterOuter>
  )
}

export const query = graphql`
  fragment footer on PrismicFooter {
    data {
      custom_code_title
      custom_code {
        text
      }
      background_image {
        gatsbyImageData
      }
      copyright
      legal_section {
        link_title
        link_url {
          uid
          type
          lang
          url
          target
        }
      }
      legal_section_title
      social_section {
        link_title
        link_url {
          uid
          type
          lang
          url
          target
        }
      }
      social_section_title
      support_section {
        link_title
        link_url {
          uid
          type
          lang
          url
          target
        }
      }
      support_section_title
    }
  }
`
