exports.linkResolver = doc => {
  switch (doc.type) {
    case 'homepage':
      return `/`
    case 'news_post':
    case 'works_post':
      // Post should have relationship to archive page
      if (typeof doc.data === 'object') {
        return `/${doc.data.relationship.uid}/${doc.uid}/`
      } else if (doc.first_publication_date) {
        switch (doc.type) {
          case 'news_post': {
            return `/news/${doc.uid}/`
          }
          case 'works_post': {
            return `/works/${doc.uid}/`
          }
        }
        // First check if it document (should have publication date property) or just link
        // throw Error(
        //   `[DEV] Project Post doesn't have a relationship. Check this: ${doc.uid}`,
        // )
      }
      return null
    default:
      return `/${doc.uid || ''}`
  }
}
