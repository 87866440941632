import React, { useEffect, useState } from 'react'
import { navigate, Link } from 'gatsby'

import styled from '@emotion/styled'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import { typography } from '@styles/text'

type LangSwitcherProps = {
  props: any
}

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en'
  }

  const lang =
    navigator && navigator.language && navigator.language.split('-')[0]
  if (!lang) return 'en'

  switch (lang) {
    case 'de':
      return 'de'
    default:
      return 'en'
  }
}

const StyledLink = styled(Link)`
  text-transform: capitalize;
`

export const LangSwitcher = ({ props }: LangSwitcherProps) => {
  const { lang } = props
  const [state, setState] = useState({
    isMobile: false,
  })

  useEffect(() => {
    const navigatorLang = getRedirectLanguage()
    if (navigatorLang === 'de') {
      const shouldRedirectToDe = props.alternate_languages.some(
        (alternate: any) => alternate.lang.slice(0, 2) === 'de',
      )

      if (shouldRedirectToDe) {
        const alternate = props.alternate_languages.find(
          (alternate: any) => alternate.document.url || alternate.url,
        )

        navigate(`${alternate.document?.url ?? ''}`)
      }
    }
  }, [])

  const mobileAlternateLangOptions = props.alternate_languages.map(
    (alternate: any) => {
      const linkResolved = alternate.document
        ? alternate.document.url
        : alternate.url
      return (
        <StyledLink to={linkResolved} key={`mobile-${alternate.lang}`}>
          {alternate.lang.slice(0, 2) === 'de' ? 'Deutsch' : 'Englisch'}
        </StyledLink>
      )
    },
  )

  return <>{mobileAlternateLangOptions}</>
}
