import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/grid/emotion'
import { Link } from 'gatsby'

import {
  oWrapperOuter,
  oLargeWrapperInner,
  oWrapperInner,
} from '@styles/05-objects/objects.wrapper'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import { typography } from '@styles/text'

export const StyledHeaderOuter = styled.header`
  ${oWrapperOuter}
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid;

  transform: translateY(-100px);
  z-index: 100;

  &.sticky-header {
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(0);
    transition: transform 300ms;
  }

  &.hide-header {
    transform: translateY(-100px);
    transition: transform 300ms;
  }

  .only-desktop {
    display: none;

    ${breakpoint.gt('md')`
    display: initial;
  `}
  }

  .only-mobile {
    display: initial;

    ${breakpoint.gt('md')`
    display: none;
  `}
  }
`

export const WrapperFlex = styled(Flex)`
  ${oWrapperInner}

  .root-link {
    z-index: 101;
    text-decoration: none;
    color: var(--header-text-color, #000);

    ${breakpoint.gt('md')`
      font-size: 1.75rem;

    `};
  }
`

export const StyledMenu = styled.ul`
  list-style: none;
  margin: 7.75rem 0 0;
  padding: 0;

  > li:not(:last-of-type) {
    margin-right: 0.75rem;
    margin-bottom: 0;
  }

  a {
    padding: 5px;
    :hover {
      text-decoration: underline;
    }

    ${breakpoint.lt('md')`
      font-size: 3rem;

    `};
  }

  ${breakpoint.gt('md')`
    display: inline-flex;
    height: 1.56rem;
    margin: 0;
  `}
`

export const NavBox = styled(Box)`
  ${breakpoint.lt('md')`
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;

      &.is-menu-open {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding: 20px 5vw;
        width: 100vw;
        height: 100vh;


          padding: 2.5rem 8vw;

        opacity: 1;
        z-index: 100;
        background-color: rgba(255 255 255 / 16%);

      @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
          &.is-menu-open {
        background-color: rgba(255 255 255 / 16%);
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(2em);
      }
      }

      }
  `}

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(255 255 255 / 16%);
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);

    ${breakpoint.gt('md')`
                border-radius: 12px;
        padding: 2px 5px;

       `};
  }
`
export const MenuButton = styled(Box)`
  background: 0;
  outline: 0;
  color: rgb(15, 15, 15);

  border: 1px solid;
  border-radius: 4px;
  padding: 0.35rem 1rem;

  ${breakpoint.gt('md')`
    display:none;
  `}

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(255 255 255 / 16%);
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);

    ${breakpoint.gt('md')`
                border-radius: 12px;
        padding: 2px 5px;

       `};
  }
`

export const AdditionalMobileItems = styled(Box)`
  ${breakpoint.gt('md')`
    display:none;
  `}
`

export const StyledNameText = styled.span``
export const StyledIcon = styled.i`
  font-style: normal;
  font-size: 30px;
  line-height: 1;
  padding: 0 1.75rem 0;
  color: var(--header-text-color, #000);
`

export const StyledNameLink = styled(Link)`
  font-weight: ${typography.weight.bold};

  ${breakpoint.gt('md')`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  `};
`

export const MoreItem = styled.li`
  position: relative;

  .chevron {
    width: 20px;
  }

  a {
    padding-top: 0;
    padding-bottom: 0;
    display: inline-flex;
    cursor: pointer;
  }
`

export const DropDownMoreLinks = styled.ul`
  padding: 0;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  background: #fff;
  border: 1px solid;
  padding: 1rem;

  li:not(:last-of-type) {
    margin-bottom: 0.75rem;
  }

  .is-dropdown-open & {
    display: block;
  }
`
